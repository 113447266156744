import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { StatusComponent } from './status/status.component';
import { CustomerComponent, NewCustomerDialogComponent } from './customer/customer.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { RouterModule, Routes } from '@angular/router';
import { MaterialImports } from './imports/material.importmodule';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { Broadcaster } from './services/broadcast.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CustomerService } from './services/customer.service';
import { CustomerdetailsComponent, GetAppIdDialogComponent } from './customerdetails/customerdetails.component';
import { SitedetailsComponent } from './sitedetails/sitedetails.component';
import { SiteConfigService } from './services/siteConfig.service';
import {
  MsalModule,
  MsalGuard,
  MsalInterceptor,
  MsalService,
  MsalBroadcastService,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import { environment } from '../environments/environment';
import {
  HardwareOverviewComponent,
  NewHardwareDeviceComponent,
  BulkAddHardwareEventComponent,
} from './hardware/hardwareoverview.component';
import { HardwareDetailsComponent } from './hardwaredetails/hardwaredetails.component';
import { HardwareService } from './services/hw.service';
import { SiteService } from './services/site.service';
import {
  SitedetailsSensorsComponent,
  ConnectSensorComponent,
} from './sitedetails-sensors/sitedetails-sensors.component';
import {
  SitedetailsTagsComponent,
  ConnectTagComponent,
  InputListOfMacsComponent,
} from './sitedetails-tags/sitedetails-tags.component';
import { HardwareSearchFieldComponent } from './hardware-search-field/hardware-search-field.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { HardwareTableComponent } from './hardware-table/hardware-table.component';
import {
  SitedetailsEdgedevicesComponent,
  ConnectEdgeDeviceComponent,
} from './sitedetails-edgedevices/sitedetails-edgedevices.component';
import { EdgeDeviceService } from './services/edgeDevice.service';
import { NewModbusConfigDialogComponent, SensorTypesComponent } from './sensor-type-definitions/sensorTypes.component';
import { SitesComponent, SetSiteReleaseTagConfigDialogComponent } from './sites/sites.component';
import { SensorTypeService } from './services/sensorType.service';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

export function loggerCallback(logLevel, message) {
  console.log('client logging' + message);
}
const appRoutes: Routes = [
  { path: 'customer', component: CustomerComponent, canActivate: [MsalGuard] },
  { path: 'customer/:customerId', component: CustomerdetailsComponent }, // ,canActivate:[AuthGuardService]},
  { path: 'customer/:customerId/site/:siteId', component: SitedetailsComponent }, // ,canActivate:[AuthGuardService]},
  {
    path: 'customer/:customerId/site/:siteId/:tabName',
    component: SitedetailsComponent,
    children: [{ path: ':id', component: HardwareDetailsComponent }],
  },
  { path: 'sites', component: SitesComponent, canActivate: [MsalGuard] },
  {
    path: 'hw',
    component: HardwareOverviewComponent,
    canActivate: [MsalGuard],
    children: [{ path: ':id', component: HardwareDetailsComponent }],
  },
  { path: 'sensor-type-definitions', component: SensorTypesComponent, canActivate: [MsalGuard] },
  { path: 'status', component: StatusComponent, canActivate: [MsalGuard] },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: '', redirectTo: '/customer', pathMatch: 'full' },
  { path: '**', component: CustomerComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    StatusComponent,
    CustomerComponent,
    PagenotfoundComponent,
    AuthCallbackComponent,
    CustomerdetailsComponent,
    SitedetailsComponent,
    GetAppIdDialogComponent,
    HardwareOverviewComponent,
    HardwareDetailsComponent,
    NewCustomerDialogComponent,
    ConnectEdgeDeviceComponent,
    ConnectSensorComponent,
    ConnectTagComponent,
    InputListOfMacsComponent,
    SitedetailsSensorsComponent,
    SitedetailsTagsComponent,
    HardwareSearchFieldComponent,
    ConfirmDialogComponent,
    NewHardwareDeviceComponent,
    BulkAddHardwareEventComponent,
    HardwareTableComponent,
    SitedetailsEdgedevicesComponent,

    SensorTypesComponent,
    NewModbusConfigDialogComponent,

    SitesComponent,
    SetSiteReleaseTagConfigDialogComponent,
  ],
  imports: [
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.authentication.clientId,
          authority: environment.authentication.authority + '/' + environment.authentication.tenantId, // "https://login.microsoftonline.com/5f92765f-23af-4383-8724-16b0db42ffd7",
          redirectUri: environment.authentication.redirectUri,
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage',
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [environment.apiAddress, [`${environment.authentication.resource}/admin`]],
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ]),
      }
    ),
    BrowserModule,
    FormsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatGridListModule,
    MatSortModule,
    MatTooltipModule,
    MaterialImports,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    Broadcaster,
    CustomerService,
    SiteService,
    HardwareService,
    SiteConfigService,
    EdgeDeviceService,
    SensorTypeService,
  ],
  entryComponents: [
    GetAppIdDialogComponent,
    NewCustomerDialogComponent,
    ConnectEdgeDeviceComponent,
    ConnectSensorComponent,
    ConnectTagComponent,
    ConfirmDialogComponent,
    NewHardwareDeviceComponent,
    BulkAddHardwareEventComponent,
    InputListOfMacsComponent,
    ConnectSensorComponent,
    NewModbusConfigDialogComponent,
    SetSiteReleaseTagConfigDialogComponent,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
