import { BulkAddHardwareToSite, SiteService, SyncConfigWearable } from './../services/site.service';
import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { HardwareService, HardwareDevice, HardwareDeviceSearch } from '../services/hw.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sitedetails-tags',
  templateUrl: './sitedetails-tags.component.html',
  styleUrls: ['./sitedetails-tags.component.css'],
})
export class SitedetailsTagsComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {}
  @ViewChild('tagTable', { static: true }) tagTable: MatTable<SyncConfigWearable>;

  displayedColumnsTags = ['select', 'id', 'bleMacAddress', 'lastObserved', 'label', 'options'];

  public selected = new SelectionModel<SyncConfigWearable>(true, []);
  private siteId: string;
  public tags: SyncConfigWearable[] = [];

  ngOnInit(): void {
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.getTags();
  }

  getTags() {
    this.siteService.getTags(this.siteId).subscribe((s) => {
      this.tags = s;
    });
  }
  onDeleteSelectedClick() {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.disconnectTags(this.selected.selected.map((t) => t.id));
        this.selected.clear();
      }
    });
  }
  isAllSelected() {
    const numSelected = this.selected.selected.length;
    const numRows = this.tags.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selected.clear();
    } else {
      this.tags.forEach((tag) => this.selected.select(tag));
    }
  }

  onNewTagClick() {
    const connectTagDialogRef = this.dialog.open(ConnectTagComponent, {
      width: '80%',
    });
    connectTagDialogRef.afterClosed().subscribe((tagIds) => {
      if (tagIds && tagIds.length > 0) {
        this.siteService
          .connectHardwareDevices(this.siteId, {
            safeMode: false,
            hardwareIds: tagIds,
          })
          .subscribe(
            (s) => {
              this.snackBar.open(s, null, { duration: 4000 });
              this.getTags();
            },
            (err: HttpErrorResponse) => {
              this.snackBar.open('There was an error connecting tags ' + err.error, null, { duration: 10000 });
              console.error(err);
            }
          );
      }
    });
  }
  deleteTag(tag: SyncConfigWearable) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.disconnectTags([tag.id]);
      }
    });
  }

  disconnectTags(tagIds: string[]) {
    const bulkRemoveHardwareToSite: BulkAddHardwareToSite = {
      safeMode: false,
      hardwareIds: tagIds,
    };
    this.siteService.disconnectHardwareDevices(this.siteId, bulkRemoveHardwareToSite).subscribe(
      (s) => {
        this.snackBar.open(s, null, { duration: 4000 });
        this.getTags();

        this.selected.clear();
      },
      (err: HttpErrorResponse) => {
        this.snackBar.open('There was an error disconnecting tags ' + err.error, null, { duration: 10000 });
        console.error(err);
      }
    );
  }

  onConnectTagsByMac() {
    const connectTagsByMacDialogRef = this.dialog.open(InputListOfMacsComponent, {
      data: {
        title: 'Connect tags by list of mac addresses.',
        deviceType: 'SRT-PT',
      },
      width: '80%',
    });
    connectTagsByMacDialogRef.afterClosed().subscribe((data) => {
      if (data.macAddresses && data.macAddresses.length > 0) {
        this.siteService
          .connectHardwareDevices(
            this.siteId,
            {
              safeMode: false,
              hardwareMacs: data.macAddresses,
              hardwareType: data.deviceType,
            },
            data.addMacIfNotExists,
            data.moveHardwareIfAlreadyConnected
          )
          .subscribe(
            (s) => {
              this.snackBar.open(s, null, { duration: 4000 });
              this.getTags();
            },
            (err: HttpErrorResponse) => {
              this.snackBar.open('There was an error connecting tags ' + err.error, null, { duration: 10000 });
              console.error(err);
            }
          );
      }
    });
  }

  onSelectTagsByMac() {
    const selectTagsByMacDialogRef = this.dialog.open(InputListOfMacsComponent, {
      data: {
        title: 'Select tags in list based on list of mac addresses.',
      },
      width: '80%',
    });
    selectTagsByMacDialogRef.afterClosed().subscribe((data) => {
      if (data.macAddresses && data.macAddresses.length > 0) {
        this.tags.forEach((tag) => {
          if (data.macAddresses.includes(tag.hardwareDevice.bleMacAddress)) {
            this.selected.select(tag);
          }
        });
      }
    });
  }
}

@Component({
  selector: 'app-connect-tag-dialog',
  template: `<mat-card>
    <h3>Connect tags</h3>
    <app-hardware-search-field (onSubmit)="queryHardwareFormSubmit($event)"></app-hardware-search-field>
    <app-hardware-table
      [(orderBy)]="query.OrderBy"
      [(orderDirection)]="query.OrderDirection"
      [(limit)]="query.Limit"
      [(page)]="query.Page"
      [(selectedHardware)]="selectedHardware"
      [hardwareDevices]="hardwareDevices"
      [displayedColumns]="displayedColumns"
      (change)="getHardwareDevices()"
    ></app-hardware-table>
    <mat-card-actions>
      <button mat-raised-button (click)="onSaveClick()">Connect all {{ selectedHardware.length }} matching tags</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class ConnectTagComponent {
  constructor(public dialogRef: MatDialogRef<ConnectTagComponent>, private hwService: HardwareService) {}
  public hardwareDevices: HardwareDevice[] = [];
  public hardwareDeviceSearch: HardwareDeviceSearch;
  public selectedHardware: HardwareDevice[] = [];
  displayedColumns = ['select', 'id', 'serial', 'macAddress', 'bleMacAddress', 'deviceType'];
  standardQuery: HardwareDeviceSearch = {
    Serial: '',
    MacAddress: '',
    DeviceType: 'SRT-PT',
    Limit: 100,
    Page: 0,
    OrderBy: 'Id',
    OrderDirection: 'asc',
  };
  query = Object.assign(this.standardQuery);
  onSaveClick(): void {
    const tagIds = this.selectedHardware.map((hd) => hd.id);
    this.dialogRef.close(tagIds);
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  queryHardwareFormSubmit(search: HardwareDeviceSearch) {
    this.query = {
      ...this.standardQuery,
      Limit: this.query.Limit,
      OrderBy: this.query.OrderBy,
      OrderDirection: this.query.OrderDirection,
      ...search,
    };
    this.getHardwareDevices();
  }
  getHardwareDevices(): void {
    this.query.DeviceType = 'SRT-PT';
    this.hwService.searchHardware(this.query).subscribe((data) => {
      this.hardwareDevices = data;
    });
  }
}

@Component({
  selector: 'app-insert-list-of-macs-dialog',
  template: `<mat-card>
    <h3>{{ data.title }}</h3>
    <form (ngSubmit)="connectTagsByMac(form)" #form="ngForm">
      <mat-form-field style="margin-left:20px;">
        <textarea
          ngModel
          required
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="20"
          name="macAddresses"
          placeholder="MacAddresses one per line."
        ></textarea>
      </mat-form-field>
      <button mat-raised-button type="submit">Confirm</button>
      <div *ngIf="data.deviceType">
        <mat-checkbox [(ngModel)]="data.addMacIfNotExists" name="addMacIfNotExists">
          Register MAC addresses if they are not found in database
        </mat-checkbox>
        <br />
        <mat-checkbox
          *ngIf="data.deviceType == 'SRT-PT'"
          [(ngModel)]="data.moveHardwareIfAlreadyConnected"
          name="moveHardwareIfAlreadyConnected"
        >
          Move hardware to this site, even if it is already registered with another site
        </mat-checkbox>
      </div>
      <input hidden name="deviceType" [(ngModel)]="data.deviceType" />
    </form>
    <mat-card-actions>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class InputListOfMacsComponent {
  constructor(
    public dialogRef: MatDialogRef<InputListOfMacsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      deviceType?: string;
      addMacIfNotExists?: boolean;
      moveHardwareIfAlreadyConnected?: boolean;
    }
  ) {}

  connectTagsByMac(form: NgForm): void {
    const macField: string = form.value.macAddresses;
    const macAddresses: string[] = macField.toLowerCase().split('\n');
    const deviceType: string | null = form.value.deviceType;
    const addMacIfNotExists: boolean = form.value.addMacIfNotExists ?? false;
    const moveHardwareIfAlreadyConnected: boolean = form.value.moveHardwareIfAlreadyConnected ?? false;
    this.dialogRef.close({ macAddresses, deviceType, addMacIfNotExists, moveHardwareIfAlreadyConnected });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
