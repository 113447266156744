import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerService, CustomerSearch, Customer } from '../services/customer.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css'],
})
export class CustomerComponent implements OnInit {
  constructor(private customerService: CustomerService, private router: Router, public dialog: MatDialog) {}

  public customerSearch: CustomerSearch = {
    name: '',
    id: null,
    Limit: 50,
    OrderBy: 'name',
    OrderDirection: 'asc',
    Page: 0,
  };
  customers: Customer[];
  public customersDataSource = new MatTableDataSource<Customer>();
  displayedColumns = ['id', 'name', 'sites'];
  ngOnInit() {
    this.search();
  }

  search() {
    this.customerService.getCustomer(this.customerSearch).subscribe((customers) => {
      this.customers = customers;
      this.customersDataSource.data = customers;
    });
  }

  newCustomer() {
    const name = '';
    const dialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: '400px',
      data: { name: name },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.name) {
        this.customerService.createCustomer(result.name).subscribe((s) => {
          this.router.navigateByUrl(`customer/${s.id}`);
        });
      }
    });
  }

  onPageChange(event: PageEvent) {
    this.customerSearch.Page = event.pageIndex;
    this.customerSearch.Limit = event.pageSize;
    this.search();
  }

  onSortChange(event: Sort) {
    this.customerSearch.OrderBy = event.active;
    this.customerSearch.OrderDirection = event.direction;
    this.search();
  }
}

@Component({
  selector: 'azure-ad-appid-dialog',
  template: `<mat-card>
    <mat-card-title> Customername </mat-card-title>
    <mat-form-field style="width:300px;">
      <input matInput [(ngModel)]="data.name" placeholder="Enter customername" />
    </mat-form-field>
    <mat-card-actions>
      <button mat-button (click)="onSaveClick()">Create</button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class NewCustomerDialogComponent {
  constructor(public dialogRef: MatDialogRef<NewCustomerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  onSaveClick(): void {
    this.dialogRef.close(this.data);
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
}
