<button mat-raised-button (click)="onNewTagClick()">Add tag</button>
<button mat-raised-button (click)="onConnectTagsByMac()">Add tags based on list of macs</button>
<button mat-raised-button (click)="onSelectTagsByMac()">Select wearables based on list of macs</button>
<button mat-button (click)="onDeleteSelectedClick()" color="warn">Remove {{ selected.selected.length }} tags.</button>
<span>{{ tags.length }} tags</span>
<mat-table #tagTable [dataSource]="tags">
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef class="w-64">
      <mat-checkbox
        (change)="$event ? masterToggle() : null"
        [checked]="selected.hasValue() && isAllSelected()"
        [indeterminate]="selected.hasValue() && !isAllSelected()"
        class="w-64"
      >
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row" class="w-64">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="$event ? selected.toggle(row) : null"
        [checked]="selected.isSelected(row)"
        class="w-64"
      >
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
    <mat-cell *matCellDef="let element"
      ><button mat-button [routerLink]="[element.hardwareDevice.id]">{{ element.hardwareDevice.id }}</button></mat-cell
    >
  </ng-container>
  <ng-container matColumnDef="bleMacAddress">
    <mat-header-cell *matHeaderCellDef> BleMacAddress </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.hardwareDevice.bleMacAddress }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lastObserved">
    <mat-header-cell *matHeaderCellDef> Last Observed </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.hardwareDevice.lastObserved }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="label">
    <mat-header-cell *matHeaderCellDef> Label </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{ element.label }} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="options">
    <mat-header-cell *matHeaderCellDef> Options </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-button color="warn" (click)="deleteTag(element)">Disconnect</button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumnsTags"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumnsTags"></mat-row>
</mat-table>
