import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { SiteService, SyncConfigNode, BulkAddHardwareToSite } from './../services/site.service';
import { Component, ViewChild, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { HardwareService, HardwareDevice, HardwareDeviceSearch } from '../services/hw.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputListOfMacsComponent } from '../sitedetails-tags/sitedetails-tags.component';

@Component({
  selector: 'app-sitedetails-sensors',
  templateUrl: './sitedetails-sensors.component.html',
  styleUrls: ['./sitedetails-sensors.component.css'],
})
export class SitedetailsSensorsComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar
  ) {}
  @ViewChild('sensorTable', { static: true }) sensorTable: MatTable<SyncConfigNode>;

  displayedColumnsSensors = ['select', 'id', 'macAddress', 'lastObserved', 'x', 'y', 'options'];
  public nodes: SyncConfigNode[] = [];
  public selected = new SelectionModel<SyncConfigNode>(true, []);
  private siteId: string;

  ngOnInit(): void {
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.getNodes();
  }

  getNodes() {
    this.siteService.getNodes(this.siteId).subscribe((s) => {
      this.nodes = s;
    });
  }
  onDeleteSelectedClick() {
    this.disconnectNodes(this.selected.selected.map((node) => node.id));
  }
  isAllSelected() {
    const numSelected = this.selected.selected.length;
    const numRows = this.nodes.length;
    return numSelected === numRows;
  }
  masterToggle() {
    if (this.isAllSelected()) {
      this.selected.clear();
    } else {
      this.nodes.forEach((node) => this.selected.select(node));
    }
  }

  onConnectNodesByMac() {
    const connectSensorByMacDialogRef = this.dialog.open(InputListOfMacsComponent, {
      data: {
        title: 'Connect nodes based on list of mac addresses.',
        deviceType: 'SRT-MN',
      },
      width: '80%',
    });
    connectSensorByMacDialogRef.afterClosed().subscribe((data) => {
      if (data.macAddresses && data.macAddresses.length > 0) {
        this.siteService
          .connectHardwareDevices(
            this.siteId,
            {
              safeMode: false,
              hardwareMacs: data.macAddresses,
              hardwareType: data.deviceType,
            },
            data.addMacIfNotExists,
            data.moveHardwareIfAlreadyConnected
          )
          .subscribe(
            (s) => {
              this.snackBar.open(`Success: ${s}`, null, { duration: 8000 });
              this.getNodes();
            },
            (err: HttpErrorResponse) => {
              this.snackBar.open('There was an error connecting nodes ' + err.error, null, { duration: 10000 });
              console.error(err);
            }
          );
      }
    });
  }

  onNewSensorClick() {
    const connectSensorDialogRef = this.dialog.open(ConnectSensorComponent, {
      width: '80%',
    });

    connectSensorDialogRef.afterClosed().subscribe((nodeIds: string[]) => {
      if (nodeIds && nodeIds.length > 0) {
        this.siteService
          .connectHardwareDevices(this.siteId, {
            safeMode: false,
            hardwareIds: nodeIds,
          })
          .subscribe(
            (s) => {
              this.snackBar.open(`Success: ${s}`, null, { duration: 8000 });
              this.getNodes();
            },
            (err: HttpErrorResponse) => {
              this.snackBar.open('There was an error connecting nodes ' + err.error, null, { duration: 10000 });
              console.error(err);
            }
          );
      }
    });
  }
  deleteSensor(node: SyncConfigNode) {
    this.disconnectNodes([node.id]);
  }

  disconnectNodes(nodeIds: string[]) {
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent);
    confirmDialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        const bulkRemoveHardwareToSite: BulkAddHardwareToSite = {
          safeMode: false,
          hardwareIds: nodeIds,
        };
        this.siteService.disconnectHardwareDevices(this.siteId, bulkRemoveHardwareToSite).subscribe(
          (s) => {
            this.snackBar.open(`Success: ${s}`, null, { duration: 8000 });
            this.getNodes();

            this.selected.clear();
          },
          (err: HttpErrorResponse) => {
            this.snackBar.open('There was an error disconnecting nodes ' + err.error, null, { duration: 10000 });
            console.error(err);
          }
        );
      }
    });
  }

  onSelectNodesByMac() {
    const selectTagsByMacDialogRef = this.dialog.open(InputListOfMacsComponent, {
      data: {
        title: 'Select tags in list based on list of mac addresses.',
      },
      width: '80%',
    });
    selectTagsByMacDialogRef.afterClosed().subscribe((data) => {
      if (data.macAddresses && data.macAddresses.length > 0) {
        this.nodes.forEach((node) => {
          if (data.macAddresses.includes(node.hardwareDevice.macAddress)) {
            this.selected.select(node);
          }
        });
      }
    });
  }
}

@Component({
  selector: 'app-connect-sensor-dialog',
  template: `<mat-card>
    <h3>Connect sensors</h3>
    <app-hardware-search-field (onSubmit)="queryHardwareFormSubmit($event)"></app-hardware-search-field>
    <app-hardware-table
      [(orderBy)]="query.OrderBy"
      [(orderDirection)]="query.OrderDirection"
      [(limit)]="query.Limit"
      [(page)]="query.Page"
      [(selectedHardware)]="selectedHardware"
      [hardwareDevices]="hardwareDevices"
      [displayedColumns]="displayedColumns"
      (change)="getHardwareDevices()"
    ></app-hardware-table>
    <mat-card-actions>
      <button mat-raised-button (click)="onSaveClick()">
        Connect all {{ selectedHardware.length }} matching hardwaredevices
      </button>
      <button mat-button (click)="onCancelClick()">Cancel</button>
    </mat-card-actions>
  </mat-card> `,
})
export class ConnectSensorComponent {
  constructor(public dialogRef: MatDialogRef<ConnectSensorComponent>, private hwService: HardwareService) {}
  public nodeIds: string[];
  public hardwareDevices: HardwareDevice[] = [];
  public hardwareDeviceSearch: HardwareDeviceSearch;
  public selectedHardware: HardwareDevice[] = [];
  displayedColumns = ['select', 'id', 'serial', 'macAddress', 'bleMacAddress', 'deviceType'];
  standardQuery: HardwareDeviceSearch = {
    Serial: '',
    MacAddress: '',
    DeviceType: 'SRT-MN',
    Limit: 100,
    Page: 0,
    OrderBy: 'Id',
    OrderDirection: 'asc',
  };
  query = Object.assign(this.standardQuery);

  onSaveClick(): void {
    this.nodeIds = this.selectedHardware.map((hd) => hd.id);
    this.dialogRef.close(this.nodeIds);
  }
  onCancelClick(): void {
    this.dialogRef.close();
  }
  queryHardwareFormSubmit(search: HardwareDeviceSearch) {
    this.query = {
      ...this.standardQuery,
      Limit: this.query.Limit,
      OrderBy: this.query.OrderBy,
      OrderDirection: this.query.OrderDirection,
      ...search,
    };
    this.getHardwareDevices();
  }
  getHardwareDevices(): void {
    this.query.DeviceType = 'SRT-MN';
    this.hwService.searchHardware(this.query).subscribe((data) => {
      this.hardwareDevices = data;
    });
  }
}
